export const generateTXTFromHtml = (textContent: string) => {
  // Create a Blob from the HTML string
  const blob = new Blob([textContent], {
    type: 'text/plain',
  });

  // Create a link element
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = 'summ-ai-word.txt';

  // Append the link to the body
  document.body.appendChild(link);

  // Trigger the download
  link.click();

  // Remove the link from the document
  document.body.removeChild(link);
};
