import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
// import Box from '@mui/material/Box';
import React, { ReactNode } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { Button, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

type Props = {
  list: { id: string; value: ReactNode }[];
  menuView: ReactNode;
  title?: string;
  type: 'iconButton' | 'button';
  name?: string;
  variant?: 'text' | 'outlined' | 'contained' | undefined;
  buttonstyle?: object;
  menuStyle?: object;
  disable?: boolean;
};

const MenuListComp = ({
  list,
  menuView,
  title,
  type,
  name,
  variant,
  buttonstyle = {},
  menuStyle = {},
  disable = false,
}: Props) => {
  const theme = useTheme();
  const { t } = useTranslation('A11y');
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const handleOpenUserMenu = (event: any) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  return (
    <>
      <Tooltip title={title}>
        {type === 'iconButton' ? (
          <IconButton
            onClick={handleOpenUserMenu}
            sx={{ p: 0, ...buttonstyle }}
            name={name}
            data-testid={name}
            role="button"
            aria-label={t('user_info')}
            disabled={disable}
          >
            {menuView}
          </IconButton>
        ) : (
          <Button
            onClick={handleOpenUserMenu}
            sx={{ textTransform: 'capitalize', ...buttonstyle }}
            name={name}
            data-testid={name}
            variant={variant}
            role="button"
            aria-label={t('langSetting')}
            disabled={disable}
          >
            {menuView}
          </Button>
        )}
      </Tooltip>
      <Menu
        sx={{ mt: theme.spacing(1), ...menuStyle }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        keepMounted
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        {list?.map(({ id, value }) => (
          <MenuItem
            onClick={handleCloseUserMenu}
            key={id}
            data-testid={value}
          >
            {value}
          </MenuItem>
        )) ?? ''}
      </Menu>
    </>
  );
};

export default MenuListComp;
