import React from 'react';
import { Form } from 'react-bootstrap';

const FormCheckBox = React.forwardRef(
  ({ label, name, onChange, onBlur, error, feedback }, ref) => (
    <>
      <Form.Check
        type="checkbox"
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        label={label}
        isInvalid={error}
        ref={ref}
        feedback={feedback}
        feedbackType="invalid"
        aria-controls={name}
      />
    </>
  )
);

export default FormCheckBox;
