// import axios from 'axios';
import http from '../../../config/httpComman';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { useState } from 'react';
import { Alert, Col, Form, Row } from 'react-bootstrap';
import styles from './RequestReviewForm.module.css';
import { useTranslation } from 'react-i18next';
import FormField from '../Basics/FormField';
import FormSelect from '../Basics/FormSelect';
import FormCheckBox from '../Basics/FormCheckBox';
import FormButton from '../Basics/FormButton';
import { shallowEqual, useSelector } from 'react-redux';
import { supabaseSelector } from 'src/redux/supabase/selector';

const RequestReviewForm = () => {
  const supabaseUser = useSelector(
    (state) => supabaseSelector(state).user,
    shallowEqual
  );
  const { t } = useTranslation('common');

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  // const languageLevels = [
  //   {
  //     value: t('requestReviewModal.languageLevel1'),
  //     label: t('requestReviewModal.languageLevel1'),
  //   },
  //   {
  //     value: t('requestReviewModal.languageLevel2'),
  //     label: t('requestReviewModal.languageLevel2'),
  //   },
  //   {
  //     value: t('requestReviewModal.languageLevel3'),
  //     label: t('requestReviewModal.languageLevel3'),
  //   },
  // ];

  const targets = [
    {
      value: t('requestReviewModal.targetGroup1'),
      label: t('requestReviewModal.targetGroup1'),
    },
    {
      value: t('requestReviewModal.targetGroup2'),
      label: t('requestReviewModal.targetGroup2'),
    },
    {
      value: t('requestReviewModal.targetGroup3'),
      label: t('requestReviewModal.targetGroup3'),
    },
    {
      value: t('requestReviewModal.targetGroup4'),
      label: t('requestReviewModal.targetGroup4'),
    },
    {
      value: t('requestReviewModal.targetGroup5'),
      label: t('requestReviewModal.targetGroup5'),
    },
  ];

  // TODO: complexitiy not needed in a first step (date 14.04.2023)
  const schema = Yup.object().shape({
    length: Yup.string().required(t('requestReviewModal.lengthRequired')),
    // complexity: Yup.string().required(
    //   t('requestReviewModal.complexityRequired')
    // ),
    target: Yup.string().required(t('requestReviewModal?.targetRequired')),
    email: Yup.string().email().required(t('requestReviewModal.emailRequired')),
    terms: Yup.bool().oneOf([true], t('requestReviewModal.termsRequired')),
  });

  const initialValues = {
    length: '',
    // complexity: t('requestReviewModal.languageLevel1'),
    target: t('requestReviewModal?.targetGroup1'),
    email: supabaseUser?.email,
    terms: false,
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting, errors },
  } = useForm({ resolver: yupResolver(schema) });

  const onSubmit = (data) => {
    try {
      setSuccess(false);
      setError(false);

      //   const domain = process.env.REACT_APP_DJANGO_BACKEND_DOMAIN;

      http
        .post('user/new/reviewrequest/', data)
        .then(function (response) {
          setSuccess(true);
          // console.log('axios post success:', response);
        })
        .catch(function (error) {
          setError(true);
          // console.log('axios post err:', error);
        });
    } catch {
      setError(true);
    }
  };
  return (
    <Form
      className={styles.form}
      onSubmit={handleSubmit(onSubmit)}
      onReset={reset}
    >
      <Row className="text-center">
        <Alert
          hidden={success === true ? false : true}
          className={styles['alert']}
          key={'success'}
          variant={'success'}
        >
          {t('requestReviewModal.successMessage')}
        </Alert>

        <Alert
          className={styles['alert']}
          hidden={error === true ? false : true}
          variant="danger"
        >
          {t('requestReviewModal.errorMessage')}
        </Alert>

        <div
          className="w-100"
          dangerouslySetInnerHTML={{ __html: t('requestReviewModal.title') }}
        />
      </Row>
      <Row>
        <Col>
          <FormField
            label={t('requestReviewModal.lengthQuestion')}
            placeholder={t('requestReviewModal.lengthPlaceholder')}
            defaultValue={initialValues.length}
            error={errors?.length ? true : false}
            feedback={errors?.length?.message}
            {...register('length')}
          />
          {/* <FormSelect
            label={t('requestReviewModal.complexityQuestion')}
            selectName="Select language level"
            defaultValue={initialValues.complexity}
            error={errors?.complexity ? true : false}
            feedback={errors?.complexity?.message}
            options={languageLevels}
            {...register('complexity')}
          /> */}
          <FormSelect
            label={t('requestReviewModal.targetQuestion')}
            defaultValue={initialValues?.target}
            error={errors?.target ? true : false}
            feedback={errors?.target?.message}
            options={targets}
            {...register('target')}
          />
          <FormField
            label={t('requestReviewModal.emailQuestion')}
            placeholder={t('requestReviewModal.emailPlaceholder')}
            defaultValue={supabaseUser?.email}
            error={errors?.email ? true : false}
            feedback={errors?.email?.message}
            {...register('email')}
          />
          <FormCheckBox
            label={t('requestReviewModal.termsLabel')}
            error={errors?.terms ? true : false}
            feedback={errors?.terms?.message}
            {...register('terms')}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormButton
            type="submit"
            loading={isSubmitting}
            label={t('requestReviewModal.submitText')}
          />
        </Col>
      </Row>
    </Form>
  );
};

export default RequestReviewForm;
