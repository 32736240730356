import { useRef, useState } from 'react';
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  Row,
  Spinner,
} from 'react-bootstrap';
import { signUpAction } from 'src/redux/supabase/actions';

// import axios from 'axios';
import http from '../config/httpComman';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { supabaseSelector } from 'src/redux/supabase/selector';
import { setMatomoUid } from 'src/utils/matomo';
import { appMessageHandling } from 'src/utils/errorHandler';

export default function SignupFormMinimal(props) {
  // ref to the form fields
  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();
  const { t } = useTranslation('common');
  const history = useNavigate();
  const dispatch = useDispatch();

  //   const { signup } = useAuth();

  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [dataprivacyAccepeted, setDataprivacyAccepted] = useState(false);

  const supabaseError = useSelector(
    (state) => supabaseSelector(state).error,
    shallowEqual
  );

  async function handleSubmit(e) {
    e.preventDefault();

    let pattern = /(?=.*[0-9a-zA-Z]).{6,}/;

    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError('Passwörter stimmen nicht überein');
    } else if (!pattern.test(passwordRef.current.value)) {
      return setError('Das Passwort muss mindestens 6 Zeichen lang sein');
    }
    const email = emailRef.current.value;

    try {
      setError('');
      setLoading(true);

      let failedSignup = await dispatch(
        signUpAction(email, passwordRef.current.value)
      );
      if (failedSignup || supabaseError) {
        appMessageHandling(dispatch, 'error.notCreatedError', 'error');
        return setLoading(false);
      } else {
        setMatomoUid(email);
        localStorage.setItem('IN_PURCHASE_FLOW', 'true');

        // OTP verify
        sessionStorage.setItem('email', email);
        history('/confirm');
        localStorage.setItem('signup_form', 'true');
        //   const domain = process.env.REACT_APP_DJANGO_BACKEND_DOMAIN;

        // await http
        //   .post('user/new/purchase/', { email: email })
        //   .then(function (response) {
        //     sessionStorage.setItem('email', email);
        //     history('/confirm');
        //     // history('/optin-waiting');
        //     // console.log('axios post success:', response);
        //   })
        //   .catch(function (error) {
        //     console.log('axios post err:', error);
        //   });
      }
    } catch {
      setError(t('signupForm.notCreatedError'));
    } finally {
      setLoading(false);
    }
  }

  return (
    <Container>
      <Row>
        <Col>
          <h4 className="pt-2 text-left mb-4">Account anlegen</h4>

          {error && <Alert variant="danger">{error}</Alert>}
          <Form
            onSubmit={handleSubmit}
            style={{ maxWidth: '100%' }}
            className="orderform-labels"
          >
            <Form.Group controlId="email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                ref={emailRef}
                required
                id="email"
              />
            </Form.Group>
            <Form.Row>
              <Form.Group
                controlId="password"
                as={Col}
              >
                <Form.Label>Passwort (mindestens 6 Zeichen)</Form.Label>
                <Form.Control
                  type="password"
                  ref={passwordRef}
                  required
                  id="password"
                />
              </Form.Group>
              <Form.Group
                controlId="password-confirm"
                as={Col}
              >
                <Form.Label>Passwort wiederholen</Form.Label>
                <Form.Control
                  type="password"
                  ref={passwordConfirmRef}
                  required
                  id="password-confirm"
                />
              </Form.Group>
            </Form.Row>

            <Form.Group controlId="data-privacy-check">
              <Form.Check
                type={'checkbox'}
                label={
                  <>
                    Ich habe die{' '}
                    <a
                      href="/dataprivacy"
                      target="_blank"
                    >
                      Datenschutzerklärung
                    </a>{' '}
                    gelesen und akzeptiert
                  </>
                }
                id={`data-privacy-check`}
                onChange={(e) => setDataprivacyAccepted(e?.target.checked)}
              />
            </Form.Group>

            <div className="text-center">
              <Button
                disabled={loading || !dataprivacyAccepeted}
                className="w-50"
                type="submit"
              >
                {loading ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    <span> Laden... </span>
                  </>
                ) : (
                  <span>Account anlegen</span>
                )}
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}
