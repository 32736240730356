import { Grid, Typography } from '@mui/material';
import React from 'react';
import InputComponent from 'src/coreUI/inputComp/InputComponent';
import MaterialSelectComp from 'src/coreUI/materialSelect/MaterialSelectComp';
import { ErrorCheck, FormData, FormDataKeys } from 'src/types/orderForm.types';
import { category } from './fixedData';
type Props = {
  formData: FormData;
  handleChange: (data: FormData | object) => void;
  handleRoleChange: (e: any) => void;
  showCategoryFreeText: boolean;
  error: ErrorCheck;
  handleEmptyFieldError: (field: FormDataKeys) => void;
};

const AccountDetail = ({
  formData,
  handleChange,
  showCategoryFreeText,
  handleRoleChange,
  error,
  handleEmptyFieldError,
}: Props) => {
  return (
    <Grid
      item
      container
      xs={12}
      textAlign={'left'}
      spacing={4}
    >
      <Grid
        item
        xs={12}
      >
        <Typography variant="h5">Accountdaten</Typography>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <InputComponent
          value={formData.email}
          disable={true}
          label="Email"
          id="email"
          handleChange={(e) => handleChange({ email: e?.target.value })}
          require={true}
          style={{ width: '100%' }}
          error={error?.email ?? false}
          handlekeyUp={(e) => handleEmptyFieldError('email')}
        />
      </Grid>
      <Grid
        item
        container
        xs={12}
        spacing={2}
      >
        <Grid
          item
          md={6}
          xs={12}
        >
          <InputComponent
            value={formData.forename}
            label="Vorname"
            id="forename"
            handleChange={(e) => handleChange({ forename: e?.target.value })}
            require={true}
            style={{ width: '100%' }}
            error={error?.forename ?? false}
            handlekeyUp={(e) => handleEmptyFieldError('forename')}
          />
        </Grid>
        <Grid
          item
          md={6}
          xs={12}
        >
          <InputComponent
            value={formData.surname}
            label="Nachname"
            id="surname"
            handleChange={(e) => handleChange({ surname: e?.target.value })}
            require={true}
            style={{ width: '100%' }}
            error={error?.surname ?? false}
            handlekeyUp={(e) => handleEmptyFieldError('surname')}
          />
        </Grid>
      </Grid>
      <Grid
        item
        container
        xs={12}
        spacing={2}
      >
        <Grid
          item
          md={6}
          xs={12}
        >
          <MaterialSelectComp
            menuItem={category}
            handleChange={(e) => {
              handleChange({ category: e?.target.value });
              handleRoleChange(e);
            }}
            value={formData.category}
            label={'Rolle'}
            disabled={false}
            formStyle={{ width: '100%', mt: 0, ml: 0 }}
            style={{ width: '100%' }}
            name={'category'}
            require={true}
            error={error?.category ?? false}
            handleMouseUp={(e) => handleEmptyFieldError('category')}
          />
        </Grid>
        <Grid
          item
          md={6}
          xs={12}
        >
          <InputComponent
            value={formData.fullname}
            label="Unternehmensname"
            id="companyName"
            handleChange={(e) => handleChange({ fullname: e?.target.value })}
            require={true}
            style={{ width: '100%' }}
            error={error?.fullname ?? false}
            handlekeyUp={(e) => handleEmptyFieldError('fullname')}
          />
        </Grid>
      </Grid>
      {showCategoryFreeText && (
        <Grid
          item
          xs={12}
        >
          <InputComponent
            value={formData.categoryFreeText}
            label="Bitte konkretisieren Sie die Rolle"
            id="categoryFreeText"
            handleChange={(e) =>
              handleChange({ categoryFreeText: e?.target.value })
            }
            require={true}
            style={{ width: '100%' }}
            error={error?.categoryFreeText ?? false}
            handlekeyUp={(e) => handleEmptyFieldError('categoryFreeText')}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default AccountDetail;
