import {
  Dispatch,
  SetStateAction,
  createContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Form } from 'react-bootstrap';
import { Grid, Container } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import CardCover from '@mui/joy/CardCover';
import theme from '../../../theme/themeConfig';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { UserSelector } from '../../../redux/user/selector';
import TextEditorComp from '../../../coreUI/textEditorComp/TextEditorComp';
import Tooltip from '@mui/material/Tooltip';
import { glossarySelector } from 'src/redux/glossary/selector';
import {
  convertParagraphAndTable,
  editorValueClear,
  replaceTextLexical,
  resetHighlight,
} from '../helper';
import { configSelector } from 'src/redux/appSetting/selector';
import SelectLangMenu from './SelectLangMenu';
import InfoIcon from './InfoIcon';
import { TranslateSelector } from 'src/redux/translation/selector';
import { useTranslation } from 'react-i18next';
import SettingCompWrapper from '../settingOptions/SettingCompWrapper';

type Props = {
  handleOutputLanguageTypeChange: (localStorageValue: string) => void;
  outputLanguage: any;
  showHighlight: boolean;
  open: any;
  outputRefEditor: any;
  showTooltipOutput: any;
  loadingTranslation: boolean;
  setOutputText: any;
  outputText: string;
  outputTextValue: any;
  placeholder: string;
  jobId: string;
  isUpdated: boolean | undefined;
  setIsUpdated: Dispatch<SetStateAction<boolean>>;
};
export const TranslationOutputContext = createContext<any>({});
const TranslationOutput = (props: Props) => {
  const { t } = useTranslation(['easy_language', 'common'], {
    nsMode: 'fallback',
  });
  // const [outputFixedValue, setOutputFixedValue] = useState('');
  const matchesSM = useMediaQuery(theme.breakpoints.down('md'));
  // const dispatch: any = useDispatch();
  const matchesMD = useMediaQuery(theme.breakpoints.down('lg'));
  const hasGroup = useSelector(
    (state: any) => UserSelector(state).hasGroup,
    shallowEqual
  );

  const userPermissionsEasy = useSelector(
    (state: any) => UserSelector(state).userPermissionsEasy,
    shallowEqual
  );
  const userPermissionsPlain = useSelector(
    (state: any) => UserSelector(state).userPermissionsPlain,
    shallowEqual
  );

  const highLightWord = useSelector(
    (state: any) => glossarySelector(state).highLightWord
  );

  const glossaryItems = useSelector(
    (state: any) => glossarySelector(state).glossaryItems
  );

  const sidebarTab = useSelector(
    (state: any) => configSelector(state).sidebarTab,
    shallowEqual
  );

  const transjob = useSelector(
    (state: any) => TranslateSelector(state).transjob,
    shallowEqual
  );

  const userDetails = useSelector(
    (state: any) => UserSelector(state).userDetails,
    shallowEqual
  );

  useEffect(() => {
    let localStorageValue =
      localStorage.getItem('outputLanguageType') || 'easy';
    let haveAll = userPermissionsEasy && userPermissionsPlain;

    if (haveAll) {
      return props.handleOutputLanguageTypeChange(localStorageValue);
    } else if (userPermissionsEasy) {
      return props.handleOutputLanguageTypeChange('easy');
    } else if (userPermissionsPlain) {
      return props.handleOutputLanguageTypeChange('plain');
    } else if (!haveAll) {
      return props.handleOutputLanguageTypeChange('easy');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPermissionsEasy, userPermissionsPlain]);

  const value = useMemo(() => {
    return {
      outputLanguage: props.outputLanguage,
      handleOutputLanguageTypeChange: props.handleOutputLanguageTypeChange,
    };
  }, [props.outputLanguage, props.handleOutputLanguageTypeChange]);

  useEffect(() => {
    // selectText(props.outputRefEditor, highLightWord);
    if (highLightWord) {
      if (
        Object.keys(highLightWord)?.length > 0 &&
        props.showHighlight &&
        props.open &&
        sidebarTab === 0
      ) {
        replaceTextLexical(props.outputRefEditor, highLightWord);
      } else if (!props.open || Object.keys(highLightWord)?.length === 0) {
        resetHighlight(props.outputRefEditor);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [highLightWord, glossaryItems, props.open]);

  useEffect(() => {
    convertParagraphAndTable(props.outputRefEditor, sidebarTab);
  }, [props.outputRefEditor, sidebarTab]);

  useEffect(() => {
    if (transjob.jobId === '-1') {
      editorValueClear(props.outputRefEditor);
    }
  }, [props.outputRefEditor, transjob.jobId]);

  return (
    <TranslationOutputContext.Provider value={value}>
      <Container
        sx={{
          padding: matchesSM ? '0 !important' : 'auto',
          margin: 0,
          maxWidth: '100% !important',
          paddingLeft: matchesMD ? 'auto' : '12px !important',
          ...(!matchesMD && { paddingLeft: '27px', paddingRight: '21px' }),
        }}
      >
        <Form.Group controlId="translationOutput">
          <Grid
            item
            container
            xs={12}
            sx={{
              justifyContent: 'space-between',
              marginBottom: '0.2rem !important',
            }}
            id="info-header-input"
          >
            <InfoIcon />
            <SelectLangMenu />
          </Grid>

          <Tooltip
            title={
              hasGroup
                ? props.showTooltipOutput
                  ? t('output.tooltip')
                  : undefined
                : t('output.hasNoGroup')
            }
            placement="top"
            {...(hasGroup && {
              open: props.showTooltipOutput,
            })}
            disableTouchListener={hasGroup}
            disableInteractive={hasGroup}
            disableFocusListener={hasGroup}
          >
            <Grid
              item
              xs={12}
              sx={{ position: 'relative' }}
            >
              {props.loadingTranslation && (
                <CardCover
                  sx={{
                    background: theme.palette.general.overlay,
                    zIndex: 2,
                  }}
                >
                  <Box sx={{ width: '100%', height: '100%' }}>
                    <LinearProgress
                      sx={{ position: 'absolute', width: '60%' }}
                      aria-label={t('translation_loading')}
                    />
                  </Box>
                </CardCover>
              )}
              <TextEditorComp
                disableInputField={props.loadingTranslation}
                inputText={props.outputText}
                refEditor={props.outputRefEditor}
                containerStyle={{ margin: 0 }}
                textValue={props.outputTextValue}
                clearEditor={false}
                placeholder={props.placeholder}
                loader={true}
                id={'translation-output'}
                showToolbar={true}
                settingComponent={<SettingCompWrapper />}
                showFormatIcon={false}
                editable={hasGroup}
                showCounter={transjob.jobId === '-1' ? false : true}
                counterTitle={t('charactercount')}
                complexityScore={
                  false &&
                  userDetails?.previewFeatures &&
                  transjob?.text_metrics
                    ? `${Math.round(
                        transjob.text_metrics.output_lix_score
                      )} (${t(transjob.text_metrics.output_lix_score_display)})`
                    : ''
                }
                complexityScoreTitle={t('complexityScoreTitle')}
                ariaLabel="Textfeld Übersetzungsausgabe"
              />
            </Grid>
          </Tooltip>
        </Form.Group>
      </Container>
    </TranslationOutputContext.Provider>
  );
};

export default TranslationOutput;
