/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import './styles.scss';
import ClickAwayListener from '@mui/material/ClickAwayListener';

type AnchoreRef = React.LegacyRef<HTMLAnchorElement> | undefined;
type DivRef = React.LegacyRef<HTMLDivElement> | undefined;
type Props = {
  options: { id: number; text: string; value: string }[];
  handleOnClick: (value: string | null) => void;
  defaultSelected: string;
  ariaLabel?: string;
  classes?: string;
};
// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = React.forwardRef(
  (
    { children, onClick, show, setShow, displayArrow }: any,
    ref: AnchoreRef
  ) => {
    return (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a
        href=""
        ref={ref}
        onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
          e.preventDefault();
          onClick(e?.target);
          setShow(!show);
        }}
        className="select-drop-down"
        style={{
          cursor: displayArrow ? 'pointer' : 'default',
        }}
      >
        {children}
        {displayArrow ? (
          show ? (
            <ArrowDropUpIcon />
          ) : (
            <ArrowDropDownIcon />
          )
        ) : null}
      </a>
    );
  }
);

const CustomMenu: any = React.forwardRef(
  (
    { children, style, className, 'aria-labelledby': labeledBy }: any,
    ref: DivRef
  ) => {
    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-label={labeledBy}
      >
        <ul className="list-unstyled">{children}</ul>
      </div>
    );
  }
);

const CustomeSelectMenu = ({
  options,
  handleOnClick,
  defaultSelected,
  ariaLabel = '',
  classes = '',
}: Props) => {
  // eslint-disable-next-line jsx-a11y/anchor-is-valid
  const [show, setShow] = useState(false);
  const handleClickAway = () => {
    setShow(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Dropdown
        className="d-inline mx-1"
        style={{ textTransform: 'capitalize' }}
      >
        <Dropdown.Toggle
          as={CustomToggle}
          id="dropdown-custom-components"
          defaultValue={defaultSelected}
          aria-label={ariaLabel}
          className={classes}
          setShow={setShow}
          show={show}
          displayArrow={options.length > 1}
          style={{
            fontSize: '1rem',
          }}
        >
          {options.find(({ value }) => value === defaultSelected)?.text}
        </Dropdown.Toggle>
        {options.length > 1 && (
          <Dropdown.Menu
            as={CustomMenu}
            className="drop-menu"
          >
            {options.map(
              ({ id, text, value }) =>
                id && (
                  <Dropdown.Item
                    key={id}
                    eventKey={value}
                    onSelect={(eventKey) => {
                      handleOnClick(eventKey);
                      setShow(false);
                    }}
                    className="drop-item"
                  >
                    {text}
                  </Dropdown.Item>
                )
            )}
          </Dropdown.Menu>
        )}
      </Dropdown>
    </ClickAwayListener>
  );
};

export default CustomeSelectMenu;
